<template>
  <div id="solution">
    <Header now-nav="/solutionList" ref="header"></Header>
    <!-- 产品介绍-->
    <div class="headerBox">
      <div class="text_Box">
        <h4>挂轨巡检机器人</h4>
        <p>代替人工巡检，挂轨自动巡检机器人</p>
        <div class="Box_note">
          <p>更快</p>
          <p>更智能</p>
          <p>更安全</p>
        </div>
      </div>
      <img :src="baseUrl+'imges/technology/newRobot/NewRobot.png'" alt="">
    </div>

    <!-- 巡检难点和产品优势-->
    <div class="contentBox">
      <!-- 巡检难点-->
      <p class="oneTitle">工业巡检难点</p>
      <!-- 巡检难点详情-->
      <div class="contentBox_difficultyBox">
        <div class="difficultyList">
          <img :src="baseUrl+'imges/technology/newRobot/difficulty_1.png'" alt="">
          <div class="PC">
            <h4>巡检场所</h4>
            <p>现场环境复杂多变</p>
            <p>（极端天气、梅雨、炎热、凝露、潮湿、积水、粉尘、易爆气体超标）</p>
            <p>设备环境数量多、分布广、信息化程度不高</p>
          </div>
          <div class="Mobile">
            <h4>巡检场所</h4>
            <p>现场环境复杂多变（极端天气、梅雨、炎热、凝露、潮湿、积水、粉尘、易爆气体超标）</p>
          </div>
        </div>
        <div class="difficultyList">
          <img :src="baseUrl+'imges/technology/newRobot/difficulty_2.png'" alt="">
          <div>
            <h4>巡检工作</h4>
            <p>管理风险大、综合成本高、故障威胁大、事故影响大</p>
          </div>
        </div>
      </div>
      <!-- 产品优势-->
      <p class="twoTitle">机器人巡检优势</p>
      <!-- 产品优势详情-->
      <div class="contentBox_advantageBox">
        <div class="advantageList">
          <img :src="baseUrl+'imges/technology/newRobot/advantage_1.svg'" alt="">
          <p>更高效</p>
        </div>
        <div class="advantageList">
          <img :src="baseUrl+'imges/technology/newRobot/advantage_2.svg'" alt="">
          <p>更全面</p>
        </div>
        <div class="advantageList">
          <img :src="baseUrl+'imges/technology/newRobot/advantage_3.svg'" alt="">
          <p>更安全</p>
        </div>
        <div class="advantageList">
          <img :src="baseUrl+'imges/technology/newRobot/advantage_4.svg'" alt="">
          <p>更智能</p>
        </div>
      </div>
    </div>

    <!-- 产品亮点-->
    <div class="lightSpot">
      <!-- 产品亮点标题-->
      <p class="ThreeTitle">产品亮点</p>
      <!-- 产品亮点详情-->
      <div class="lightSpot_Box">
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_1.svg'" alt="">
          <p>轻巧灵活，环境适应性强</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_2.svg'" alt="">
          <p>模块化设计，功能需求自由组合</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_3.svg'" alt="">
          <p>全时段、全方位、细节无遗漏</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_4.svg'" alt="">
          <p>环境监测技术，实时分析预警</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_5.svg'" alt="">
          <p>智能自动巡检，提升运维效率</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_6.svg'" alt="">
          <p>热成像检测，防患于未“燃”</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_7.svg'" alt="">
          <p>支持APP远程操控、管理更便捷</p>
        </div>
        <div class="lightSpot_Box_List">
          <img :src="baseUrl+'imges/technology/newRobot/lightspot_8.svg'" alt="">
          <p>搭载AI平台，可按需拓展AI功能</p>
        </div>
      </div>
    </div>

    <!-- 挂轨巡检机器人优势-->
    <div class="railRobot">
      <!-- 挂轨巡检机器人优势标题-->
      <p class="fourTitle">挂轨巡检机器人优势</p>
      <!-- 挂轨巡检机器人优势盒子-->
      <div class="railRobot_advantage">
        <img :src="baseUrl+'imges/technology/newRobot/Rail_advantage.png'" alt="">
      </div>
    </div>

    <!-- 挂轨巡检机器人效用-->
    <div class="avail">
      <!-- 挂轨巡检机器人效用标题-->
      <p class="fiveTitle">挂轨巡检机器人效用</p>
      <!-- 挂轨巡检机器人效用盒子-->
      <div class="avail_Box">
        <div class="avail_Box_List">
          <h4>自动巡检更安全</h4>
          <p>巡检远程化、自动化</p>
          <p>巡检实现多模式巡检、按需巡检</p>
          <p>工作量大、巡检空间受限、人工巡检有局限</p>
          <p>巡检环境危险性高</p>
        </div>
        <div class="avail_Box_List">
          <h4>状态识别更准确</h4>
          <p>机器识别更准确</p>
          <p>数据分析更快速</p>
          <p>问题闭环处理更完整</p>
        </div>
        <div class="avail_Box_List">
          <h4>节约人力更精益</h4>
          <p>减少巡检人员，降低管理成本</p>
          <p>提升巡检效率，为企业创造价值</p>
          <p>巡检频次有保证</p>
          <p>巡检报告自动生成，减少人工记录时间成本</p>
        </div>
        <div class="avail_Box_List">
          <h4>预警告警更及时</h4>
          <p>处理策略</p>
          <p>告警数据</p>
          <p>异常告警</p>
        </div>
      </div>
    </div>

    <!-- 挂轨巡检机器巡检管理平台-->
    <div class="management">
      <!-- 挂轨巡检机器巡检管理平台标题-->
      <p class="sixTitle">挂轨巡检机器巡检管理平台</p>
      <!-- 挂轨巡检机器巡检管理平台详情-->
      <div class="management_Box">
        <!-- 详情左侧图片-->
        <div class="left_Box">
          <img :src="baseUrl+'imges/technology/newRobot/management_left.png'" alt="">
        </div>
        <!-- 详情右侧图标-->
        <div class="right_Box">
          <div class="right_Box_List">
            <img :src="baseUrl+'imges/technology/newRobot/management_right_1.svg'" alt="">
            <p>数据中心</p>
          </div>
          <div class="right_Box_List">
            <img :src="baseUrl+'imges/technology/newRobot/management_right_2.svg'" alt="">
            <p>巡检管理</p>
          </div>
          <div class="right_Box_List">
            <img :src="baseUrl+'imges/technology/newRobot/management_right_3.svg'" alt="">
            <p>巡检管理</p>
          </div>
          <div class="right_Box_List">
            <img :src="baseUrl+'imges/technology/newRobot/management_right_4.svg'" alt="">
            <p>系统管理</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 监控系统+环境监测联动-->
    <div class="camera">
      <!-- 监控系统+环境监测联动标题-->
      <p class="sevenTitle">挂轨巡检机器人+监控系统+环境监测联动</p>
      <div class="camera_Box" >
        <img :src="baseUrl+'imges/technology/newRobot/camera.png'" alt="">
      </div>
    </div>

    <!-- 应用场景-->
    <div class="application">
      <!-- 应用场景标题-->
      <p class="eightTitle">应用场景</p>
      <span>市政地下管廊（空间封闭拥挤、有毒介质危害）、智慧化工园区（智能化水平偏低，环境和安全问题严重）、机房巡检、养殖业</span>
      <div class="application_Box">
        <div class="Box_smallImg">
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img1.svg'" alt="">
            <p>地铁系统</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img2.svg'" alt="">
            <p>桥廊隧道</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img3.svg'" alt="">
            <p>厂区、港口<br>空中管道</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img4.svg'" alt="">
            <p style="color: #000000">油田矿山</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img5.svg'" alt="">
            <p>发电厂</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img6.svg'" alt="">
            <p>高压变电站</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img7.svg'" alt="">
            <p>易燃易爆<br>
              危险品仓库</p>
          </div>
          <div class="smallImg_List">
            <img :src="baseUrl+'imges/technology/newRobot/small_Img8.svg'" alt="">
            <p>其他复杂环境</p>
          </div>
        </div>
        <div class="Box_bigImg">
          <img :src="baseUrl+'imges/technology/newRobot/big_Img.svg'" alt="" class="PC">
          <img :src="baseUrl+'imges/technology/newRobot/big_Img.png'" alt="" class="Mobile">
          <p>地下城市综合管廊</p>
        </div>
      </div>
    </div>

    <!-- 技术参数-->
    <div class="technical">
      <!-- 技术参数标题-->
      <p class="nineTitle">技术参数</p>
      <!-- 技术参数详情-->
      <div class="technical_Box">
        <div class="technical_Box_gray">
          <h4>外形尺寸</h4>
          <p>L680×B346×H410（仅参考）</p>
        </div>
        <div class="technical_Box_white">
          <h4>产品重量</h4>
          <p>≤40Kg（仅参考）</p>
        </div>
        <div class="technical_Box_gray">
          <h4>防护等级</h4>
          <p>IP65</p>
        </div>
        <div class="technical_Box_white">
          <h4>行走方式</h4>
          <p>吊轨行走</p>
        </div>
        <div class="technical_Box_gray">
          <h4>移动速度</h4>
          <p>≤1m/s（可调）</p>
        </div>
        <div class="technical_Box_white">
          <h4>转弯半径</h4>
          <p>最小半径1米，可根据需求定制</p>
        </div>
        <div class="technical_Box_gray">
          <h4>工作温度</h4>
          <p>-10℃～+60℃</p>
        </div>
        <div class="technical_Box_white">
          <h4>充电方式</h4>
          <p>滑触式充电/手动人工充电</p>
        </div>
        <div class="technical_Box_gray">
          <h4>图像分辨率</h4>
          <p>1920 × 1080 @ 30fps</p>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Technologylist",
  data() {
    return {
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      //当前解决方案名称
      thisSolutionName: this.$route.params.name||"挂轨巡检机器人"
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.openTechnology()
  },
  methods: {
    //右侧导航点击事件
    thisPageType(e) {
      this.thisSolutionName = e;
    }
  }
}
</script>

<style lang="less" scoped>
#solution {
  background: #FFFFFF;
  //产品介绍
  .headerBox{
    display: flex;
    justify-content: left;
    width: 100%;
    height: 580px;
    background-image: url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/robot_text_BG.svg"),url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/robotBG.png");
    background-repeat: no-repeat;
    background-size: 431.75px 284.5px, 100% 580px;
    background-position: 339px 91px, 0 0;
    //文字框
    .text_Box{
      width: 500px;
      margin: 200px 65px 0 447px;
      text-align: left;
      h4{
        font-family: Medium;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #0564AF;
      }
      p{
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #0564AF;
      }
      //小蓝框
      .Box_note{
        display: flex;
        justify-content: left;
        margin-top: 86px;
        p{
          height: 34px;
          padding: 0 20px;
          margin-right: 16px;
          border-radius: 17px;
          background: #0564AF;
          font-family: Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 34px;
          letter-spacing: 0em;
          color: #FFFFFF;
        }
        p:hover{
          cursor: default;
          user-select: none;
          transform: scale(1.1);
          transition: all .8s;
        }
      }
    }
    img{
      width: 822px;
      height: 554px;
    }
  }

  //巡检难点和产品优势
  .contentBox{
    position: relative;
    width: 1444px;
    height: 1425px;
    margin: 0 auto;
    //工业巡检难点
    .oneTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 105px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .oneTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //巡检难点盒子
    .contentBox_difficultyBox{
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 239px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 454px;
      //巡检难点图片
      .difficultyList{
        position: relative;
        width: 700px;
        height: 100%;
        overflow: hidden;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
        .PC{
          display: block;
        }
        .Mobile{
          display: none;
        }
        div{
          position: absolute;
          bottom: 0;
          left: 0;
          box-sizing: border-box;
          padding: 40px 0 0 58px;
          width: 100%;
          height: 198px;
          background: rgba(0,0,0,.4);
          text-align: left;
          cursor: default;
          user-select: none;
          h4{
            font-family: Medium;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            margin-bottom: 9px;
          }
          p{
            font-family: Regular;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: 0.05em;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 3px;
          }
        }
      }
      //鼠标经过放大图片
      .difficultyList:hover{
        img{
          transform: scale(1.1);
          transition: all .8s;
        }
      }
    }
    //机器人产品优势
    .twoTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 860px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .twoTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //产品优势盒子
    .contentBox_advantageBox{
      position: absolute;
      top: 1014px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      width: 1283px;
      height: 280px;
      //产品优势图片
      .advantageList{
        img{
          width: 200px;
          height: 200px;
        }
        p{
          margin-top: 56px;
          font-family: Medium;
          font-size: 20px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0em;
          color: #3D3D3D;
        }
      }
      //鼠标经过放大图标
      .advantageList:hover{
        img{
          transform: scale(1.1);
          transition: all .8s;
        }
      }
    }
  }

  //产品亮点
  .lightSpot{
    position: relative;
    width: 100%;
    height: 800px;
    background: #F4F5F9;
    //产品亮点标题
    .ThreeTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 98px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .ThreeTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //产品亮点详情
    .lightSpot_Box{
      position: absolute;
      top: 245px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1174px;
      height: 340px;
      .lightSpot_Box_List{
        width: 272px;
        height: 149px;
        border-radius: 12px;
        background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 9px 11px 5px rgba(69, 132, 212, 0.09);
        text-align: center;
        box-sizing: border-box;
        padding-top: 23px;
        img{
          width: 60px;
          height: 60px;
        }
        p{
          margin-top: 21px;
          font-family: Regular;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          letter-spacing: 0.05em;
          color: #3D3D3D;
        }
      }
      .lightSpot_Box_List:hover{
        transform: scale(1.1);
        transition: all .4s;
      }
    }
  }

  //挂轨巡检机器人优势
  .railRobot{
    position: relative;
    width: 100%;
    height: 800px;
    //挂轨巡检机器人标题
    .fourTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 94px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .fourTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //挂轨巡检机器人盒子
    .railRobot_advantage{
      position: absolute;
      top: 196px;
      left: 50%;
      transform: translateX(-50%);
      width: 530px;
      height: 551px;
      img{
        width: 100%;
        height: 100%;
      }
      img:hover{
        transform: scale(1.1);
        transition: all .7s;
      }
    }
  }

  //挂轨巡检机器人效用
  .avail{
    position: relative;
    width: 100%;
    height: 800px;
    background: #F4F5F9;
    //挂轨巡检机器人效用标题
    .fiveTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 158px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .fiveTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //挂轨巡检机器人效用盒子
    .avail_Box{
      position: absolute;
      top: 322px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      width: 1546px;
      height: 212px;
      .avail_Box_List{
        width: 364px;
        height: 212px;
        border-radius: 12px;
        background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 9px 11px 5px rgba(69, 132, 212, 0.09);
        box-sizing: border-box;
        padding: 30px 0 0 40px;
        text-align: left;
        h4{
          font-family: Medium;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #3D3D3D;
          margin-bottom: 18px;
        }
        p{
          font-family: Regular;
          font-size: 14px;
          font-weight: normal;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: #3D3D3D;
          background-image: url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/avail_Box_List_p.svg");
          background-repeat: no-repeat;
          background-position: 0 5px;
          box-sizing: border-box;
          padding-left: 23px;
        }
      }
      .avail_Box_List:hover{
        transform: scale(1.1);
        transition: all .4s;
      }
    }
  }

  //挂轨巡检机器巡检管理平台
  .management{
    position: relative;
    width: 100%;
    height: 800px;
    background: #242933;
    //挂轨巡检机器巡检管理平台标题
    .sixTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 102px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
    .sixTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //挂轨巡检机器巡检管理平台详情
    .management_Box{
      position: absolute;
      top: 263px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      width: 1264px;
      height: 393px;
      .left_Box{
        width: 670px;
        height: 393px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right_Box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: space-between;
        width: 505px;
        height: 356px;
        margin-top: 16px;
        .right_Box_List{
          width: 240px;
          height: 166px;
          border-radius: 5px;
          background: linear-gradient(125deg, #282E39 4%, #242933 100%);
          box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),-10px -10px 20px 0px rgba(255, 255, 255, 0.01);
          box-sizing: border-box;
          padding-top: 33px;
          text-align: center;
          img{
            width: 66px;
            height: 56px;
          }
          p{
            margin-top: 18px;
            font-family: Medium;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0.05em;
            color: #FFFFFF;
          }
        }
        .right_Box_List:hover{
          transform: scale(1.1);
          transition: all .4s;
        }
      }
    }

  }

  //挂轨巡检机器人+监控系统+环境监测联动
  .camera{
    position: relative;
    width: 100%;
    height: 800px;
    //挂轨巡检机器人+监控系统+环境监测联动标题
    .sevenTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 89px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .sevenTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //挂轨巡检机器人+监控系统+环境监测联动详情
    .camera_Box{
      position: absolute;
      top: 216px;
      left: 50%;
      transform: translateX(-50%);
      width: 935px;
      height: 516px;
      img{
        width: 100%;
        height: 100%;
      }
      img:hover{
        transform: scale(1.1);
        transition: all .7s;
      }
    }
  }

  //应用场景
  .application{
    position: relative;
    width: 100%;
    height: 800px;
    background: #F4F5F9;
    //应用场景标题
    .eightTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 78px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .eightTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    span{
      position: absolute;
      top: 169px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      font-family: Regular;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0em;
      color: #3D3D3D;
    }
    //应用场景详情
    .application_Box{
      position: absolute;
      top: 288px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      width: 1444px;
      height: 319px;
      //左侧小图片
      .Box_smallImg{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: space-between;
        width: 1003px;
        height: 100%;
        //小图片列表
        .smallImg_List{
          position: relative;
          width: 240px;
          height: 153px;
          overflow: hidden;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          img:hover{
            transform: scale(1.1);
            transition: all .6s;
          }
          p{
            position: absolute;
            left: 21px;
            top: 18px;
            cursor: default;
            user-select: none;
            font-family: Medium;
            font-size: 18px;
            font-weight: normal;
            letter-spacing: 0.05em;
            color: #FFFFFF;
          }
        }
      }
      //右侧大图片
      .Box_bigImg{
        position: relative;
        width: 425px;
        height: 319px;
        overflow: hidden;
        .PC{
          display: block;
        }
        .Mobile{
          display: none;
        }
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img:hover{
          transform: scale(1.1);
          transition: all .6s;
        }
        p{
          position: absolute;
          left: 24px;
          bottom: 24px;
          cursor: default;
          user-select: none;
          font-family: Medium;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #FFFFFF;
        }
      }
    }

  }

  //技术参数
  .technical{
    position: relative;
    width: 100%;
    height: 908px;
    //技术参数标题
    .nineTitle{
      position: absolute;
      width: 1142px;
      height: 42px;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .nineTitle:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    //技术参数详情
    .technical_Box{
      position: absolute;
      top: 204px;
      left: 50%;
      transform: translateX(-50%);
      width: 1122px;
      height: 612px;
      .technical_Box_gray, .technical_Box_white{
        display: flex;
        justify-content: left;
        width: 100%;
        height: 68px;
        box-sizing: border-box;
        padding-left: 102px;
        background: #F4F4F4;
        text-align: left;
        h4{
          width: 181px;
          font-family: Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 68px;
          letter-spacing: 0em;
          color: #3D3D3D;
        }
        p{
          margin-left: 50px;
          font-family: Regular;
          font-size: 16px;
          font-weight: normal;
          line-height: 68px;
          letter-spacing: 0em;
          color: #3D3D3D;
        }
      }
      .technical_Box_white{
        background: #FFFFFF !important;
      }
    }

  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
    margin: 0;
    padding: 0;
  }
  #solution {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    //产品介绍
    .headerBox{
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      height: 590px;
      margin-top: 64px;
      background-image: url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/robot_text_BG.svg"),url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/robotBG_Mobile.png");
      background-repeat: no-repeat;
      background-position: 25px 63px, 0 0;
      background-size: 262.75px 173px, 100% 590px;
      //文字框
      .text_Box{
        width: 270px;
        margin: 128px 0 0 73.53px;
        h4{
          width: 100%;
          font-family: Medium;
          font-size: 30px;
          font-weight: 500;
          letter-spacing: 0em;
          color: #0564AF;
        }
        p{
          width: 100%;
          margin-top: 5px;
          font-family: Regular;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #0564AF;
        }
        //小蓝框
        .Box_note{
          margin-top: 50px;
          p{
            width: 53px;
            height: 25px;
            text-align: center;
            margin-right: 11px;
            border-radius: 17px;
            background: #0564AF;
            font-family: Medium;
            font-size: 12px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0em;
            color: #FFFFFF;
          }
        }
      }
      img{
        width: 100%;
        height: 260px;
      }
    }

    //巡检难点和产品优势
    .contentBox{
      position: relative;
      width: 100%;
      height: 1014px;
      margin: 0 auto;
      //工业巡检难点
      .oneTitle{
        position: absolute;
        width: 100%;
        height: 29px;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #454545;
      }
      .oneTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //巡检难点盒子
      .contentBox_difficultyBox{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 96px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 434px;
        .difficultyList {
          position: relative;
          width: 100%;
          height: 207px;
          overflow: hidden;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .PC{
            display: none;
          }
          .Mobile{
            display: block;
          }
          div{
            position: absolute;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            padding: 15px 0 0 20px;
            height: 103px;
            background: rgba(0,0,0,.4);
            text-align: left;
            cursor: default;
            user-select: none;
            h4{
              font-family: Medium;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0.05em;
              color: #FFFFFF;
            }
            p{
              width: 95%;
              font-family: Regular;
              font-size: 10px;
              font-weight: normal;
              line-height: 16px;
              letter-spacing: 0.05em;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
      //机器人产品优势
      .twoTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 596px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .twoTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //产品优势盒子
      .contentBox_advantageBox{
        position: absolute;
        top: 675px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: space-between;
        width: 80%;
        height: 262px;
        //产品优势图片
        .advantageList{
          width: 48%;
          img{
            width: 80px;
            height: 80px;
          }
          p{
            margin-top: 13px;
            font-family: Regular;
            font-size: 14px;
            font-weight: normal;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0em;
            color: #3D3D3D;
          }
        }
      }
    }

    //产品亮点
    .lightSpot{
      position: relative;
      width: 100%;
      height: 633px;
      background: #F4F5F9;
      //产品亮点标题
      .ThreeTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 29px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .ThreeTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //产品亮点详情
      .lightSpot_Box{
        position: absolute;
        top: 99px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 92%;
        height: 470px;
        .lightSpot_Box_List{
          pointer-events: none;
          width: 48%;
          height: 110px;
          border-radius: 12px;
          background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
          border: 1px solid #FFFFFF;
          box-shadow: 0px 9px 11px 5px rgba(69, 132, 212, 0.09);
          text-align: center;
          box-sizing: border-box;
          padding-top: 14px;
          img{
            width: 34px;
            height: 34px;
          }
          p{
            width: 100%;
            margin: 13px auto 0;
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.05em;
            color: #3D3D3D;
          }
        }
      }
    }

    //挂轨巡检机器人优势
    .railRobot{
      position: relative;
      width: 100%;
      height: 451px;
      //挂轨巡检机器人标题
      .fourTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 57px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .fourTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //挂轨巡检机器人盒子
      .railRobot_advantage{
        position: absolute;
        top: 142px;
        left: 50%;
        transform: translateX(-50%);
        width: 240px;
        height: 249px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    //挂轨巡检机器人效用
    .avail{
      position: relative;
      width: 100%;
      height: 1109px;
      background: #F4F5F9;
      //挂轨巡检机器人效用标题
      .fiveTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 48px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .fiveTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //挂轨巡检机器人效用盒子
      .avail_Box{
        position: absolute;
        top: 127px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        height: 902px;
        .avail_Box_List{
          pointer-events: none;
          width: 100%;
          height: 212px;
          border-radius: 12px;
          background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
          border: 1px solid #FFFFFF;
          box-shadow: 0px 9px 11px 5px rgba(69, 132, 212, 0.09);
          box-sizing: border-box;
          padding: 35px 0 0 40px;
          text-align: left;
          h4{
            font-family: Medium;
            font-size: 18px;
            font-weight: normal;
            letter-spacing: 0.05em;
            color: #3D3D3D;
            margin-bottom: 18px;
          }
          p{
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: #3D3D3D;
            background-image: url("http://www.cd-rst.top:18000/static/imges/technology/newRobot/avail_Box_List_p.svg");
            background-repeat: no-repeat;
            background-position: 0 5px;
            box-sizing: border-box;
            padding-left: 24px;
          }
        }
      }
    }

    //挂轨巡检机器巡检管理平台
    .management{
      position: relative;
      width: 100%;
      height: 687px;
      background: #242933;
      //挂轨巡检机器巡检管理平台标题
      .sixTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 62px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #FFFFFF;
      }
      .sixTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //挂轨巡检机器巡检管理平台详情
      .management_Box{
        position: absolute;
        top: 181px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 442px;
        .left_Box{
          width: 276px;
          height: 161px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .right_Box{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-content: space-between;
          width: 100%;
          height: 235px;
          margin-top: 46px;
          .right_Box_List{
            pointer-events: none;
            width: 48%;
            height: 110px;
            margin: 0 auto;
            border-radius: 5px;
            background: linear-gradient(125deg, #282E39 4%, #242933 100%);
            box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),-10px -10px 20px 0px rgba(255, 255, 255, 0.01);
            box-sizing: border-box;
            padding-top: 21.87px;
            text-align: center;
            img{
              width: 43.73px;
              height: 37.11px;
            }
            p{
              margin-top: 12px;
              font-family: Medium;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              letter-spacing: 0.05em;
              color: #FFFFFF;
            }
          }
        }
      }
    }

    //挂轨巡检机器人+监控系统+环境监测联动
    .camera{
      position: relative;
      width: 100%;
      height: 430px;
      //挂轨巡检机器人+监控系统+环境监测联动标题
      .sevenTitle{
        position: absolute;
        width: 87%;
        height: 42px;
        top: 102px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .sevenTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 64px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //挂轨巡检机器人+监控系统+环境监测联动详情
      .camera_Box{
        position: absolute;
        top: 207px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 180px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    //应用场景
    .application{
      position: relative;
      width: 100%;
      height: 762px;
      background: #FFFFFF;
      //应用场景标题
      .eightTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .eightTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      span{
        display: none;
      }
      //应用场景详情
      .application_Box{
        position: absolute;
        top: 119px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: space-between;
        width: 90%;
        height: 636px;
        //左侧小图片
        .Box_smallImg{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-content: space-between;
          width: 100%;
          height: 429px;
          //小图片列表
          .smallImg_List{
            position: relative;
            width: 49%;
            height: 99px;
            overflow: hidden;
            img{
              pointer-events: none;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              // 设置图片形变
              object-fit: cover;
            }
            p{
              position: absolute;
              left: 13.95px;
              top: 12.63px;
              cursor: default;
              user-select: none;
              font-family: Medium;
              font-size: 12px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #FFFFFF;
            }
          }
        }
        //右侧大图片
        .Box_bigImg{
          position: relative;
          width: 100%;
          height: 207px;
          overflow: hidden;
          margin-top: 10px;
          .PC{
            display: none;
          }
          .Mobile{
            display: block;
          }
          img{
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          p{
            position: absolute;
            left: 18.46px;
            bottom: 16.41px;
            cursor: default;
            user-select: none;
            font-family: Medium;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.05em;
            color: #FFFFFF;
          }
        }
      }
    }

    //技术参数
    .technical{
      position: relative;
      width: 100%;
      height: 820px;
      //技术参数标题
      .nineTitle{
        position: absolute;
        width: 100%;
        height: 42px;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
      }
      .nineTitle:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 32px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      //技术参数详情
      .technical_Box{
        position: absolute;
        top: 115px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 612px;
        .technical_Box_gray, .technical_Box_white{
          display: flex;
          justify-content: left;
          width: 100%;
          height: 68px;
          box-sizing: border-box;
          padding-left: 21px;
          background: #F4F4F4;
          text-align: left;
          h4{
            width: 83px;
            font-family: Medium;
            font-size: 12px;
            font-weight: 500;
            line-height: 68px;
            letter-spacing: 0em;
            color: #3D3D3D;
          }
          p{
            margin-left: 11px;
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 68px;
            letter-spacing: 0em;
            color: #3D3D3D;
          }
        }
      }
    }
  }
}
</style>
